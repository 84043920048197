<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Collection" route="website.collections" />
        <div class="page-header">
          <h2 class="page-title">Add Collection</h2>
        </div>
      </v-col>
    </v-row>
    <GroupSubmit mode="create" :isLoading="isLoading" :callbackCancel="onCancel" :callbackSubmit="onSubmit" />
    <v-row>
      <v-col cols="12">
        <Notification type="error" :messages="errors" v-if="errors.length > 0" />
      </v-col>
      <v-col cols="12" sm="8" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <InputDefault :model="['attributes', 'title']" fieldName="Title" label="Title" :isValidate="true" />
          <!-- <ContentInput title="Description" :v-model="attributes.bodyHtml" subtitle="Maximum 100.000 characters" /> -->
          <ContentInput :v-model="attributes.bodyHtml" title="Description" subtitle="Maximum 100.000 characters" />
        </div>
        <div class="elevation-form pa-3 mt-3">
          <InputDefault
            :model="['attributes', 'metaDescription']"
            fieldName="Title"
            :height="150"
            label="Meta Description"
            :isValidate="true"
            :min="0"
            :max="160"
            typeComponent="metaDes"
          />
        </div>
        <div class="elevation-form pa-3 mt-3">
          <CollectionType />
          <transition name="slide-value">
            <div v-if="attributes.collectionType === 'normal'" class="px-3 pb-4">
              <v-divider class="mb-3"></v-divider>
              <h3 class="mb-3">Products</h3>
              <v-select
                v-model="productFilter.type"
                item-text="name"
                item-value="value"
                :items="productFilter.types"
                @change="onFilterProduct"
              ></v-select>
              <v-text-field
                v-model="productFilter.search"
                placeholder="Search product"
                @input="
                  () => {
                    if (productFilter.search) showModalSelectProduct = true;
                    getProductList();
                  }
                "
              ></v-text-field>
              <v-list
                v-if="!isLoadingProductList && productSelecteds.length"
                :max-height="700"
                style="overflow-y: auto;"
              >
                <draggable v-model="productSelecteds">
                  <v-list-item
                    v-for="(product, index) in productSelecteds"
                    :key="product._id"
                    class="pl-0"
                    style="gap: 12px; border-bottom: solid 1px #dadce0;"
                    two-line
                  >
                    <h4 class="pr-2">{{ index + 1 }}</h4>
                    <v-list-item-avatar class="rounded">
                      <img
                        :src="
                          product.images && product.images.length
                            ? imageHelpers.url(product.images[0].src)
                            : '@/assets/images/no-image.png'
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content class="pl-0" style="position: relative;">
                      <router-link
                        class="product-link"
                        :to="{ name: 'website.products.update', params: { id: product._id } }"
                        title="View product"
                        target="_blank"
                      >
                        {{ product.title }} {{ product.totalSales && ` (${product.totalSales} sales)` }}
                      </router-link>
                      <button
                        style="position: absolute;right: 0; top: 50%; transform: translateY(-50%); z-index: 1;"
                        @click="onDeleteProduct(product._id)"
                      >
                        <span class="mdi mdi-trash-can-outline mdi-18px"></span>
                      </button>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
              </v-list>
              <div v-if="productSelecteds.length === 0" class="d-flex flex-column align-center">
                <img :src="imageFake.Emty" class="mb-2" width="50px" />
                <p>There are no products</p>
              </div>
            </div>
          </transition>
        </div>
        <div class="mt-4">
          <v-col cols="12" sm="12" xs="12" class="pa-3 elevation-form">
            <template>
              <v-row justify="center">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <div>
                        <h3>Search engine listing preview</h3>
                        <p class="mt-5">
                          Add a title and description to see how this collection might appear in a search engine listing
                        </p>
                      </div>
                      <template v-slot:actions>
                        <span class="btn-link">Edit website SEO</span>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <div>
                          <span>Page title</span>
                          <v-text-field v-model="attributes.metafieldsGlobalTitleTag"></v-text-field>
                        </div>
                        <div>
                          <span>Meta description</span>
                          <v-textarea v-model="attributes.metafieldsGlobalDescriptionTag"></v-textarea>
                        </div>
                        <div>
                          <span>URL and handle</span>
                          <v-text-field v-model="attributes.metafieldsGlobalUrl" :prefix="domain"></v-text-field>
                        </div>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </template>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <CollectionAvailability />
        </div>
        <div class="elevation-form pa-3 mt-4">
          <h4 class="mb-3">Collection image</h4>
          <UploadOneImage :image="attributes.image" @callback="getImageUpLoad" />
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
        <v-btn :loading="isLoading" color="primary" :disabled="isLoading" @click="onSubmit">Create</v-btn>
      </v-col>
    </v-row>
    <BeforeLeavePage />

    <v-dialog persistent v-model="showModalSelectProduct" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline d-flex justify-space-between">
          Select products
          <!-- Close icon -->
          <button @click="showModalSelectProduct = false">
            <v-icon>fas fa-times</v-icon>
          </button>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters justify="center">
            <v-col cols="8">
              <v-text-field
                autofocus
                v-model="productFilter.search"
                placeholder="Search product"
                @input="onSearchProduct"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-list v-if="!isLoadingProductList && productList.length" :max-height="500" style="overflow-y: auto;">
            <v-list-item v-for="product in productList" :key="product._id" class="px-0" style="gap: 8px" two-line>
              <v-checkbox v-model="attributes.productIds" :value="product._id"></v-checkbox>
              <v-list-item-avatar class="rounded">
                <img
                  :src="
                    product.images && product.images.length
                      ? imageHelpers.url(product.images[0].src)
                      : '@/assets/images/no-image.png'
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content class="pl-2">
                <router-link
                  class="product-link"
                  :to="{ name: 'website.products.update', params: { id: product._id } }"
                  title="View product"
                  target="_blank"
                >
                  {{ product.title }} {{ product.totalSales && ` (${product.totalSales} sales)` }}
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <div v-if="isLoadingProductList" class="d-flex justify-center align-center py-3">
            <page-loader />
          </div>
          <div v-if="!isLoadingProductList && productList.length === 0" class="d-flex flex-column align-center">
            <img :src="imageFake.Emty" class="mb-2" width="50px" />
            <p>No Product</p>
          </div>
        </v-card-text>

        <!-- Footer -->
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn @click="onClosePopup"> Close </v-btn>
          <v-btn class="ml-3" color="primary" @click="onSaveProductSelected"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { collectionApi } from '@/apis/collection';
import draggable from 'vuedraggable';
import ContentInput from '@/views/website/components/Editor';
import CollectionAvailability from './components/CollectionAvailability';
import CollectionType from './components/CollectionType';
import { productApi } from '@/apis/product';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    ContentInput,
    CollectionAvailability,
    CollectionType,
    draggable,
  },
  data() {
    let defaultForm = {
      title: '',
      bodyHtml: '',
      metaDescription: '',
      image: {
        src: '',
        position: 1,
      },
      rules: [{ column: 'tags', relation: '', condition: null }], // [{column,relation,condition}]
      collectionType: 'smart',
      handle: '',
      disjunctive: true,
      published: true,
      upsellOffer: true,
      upsellWidget: true,
      productIds: [],
    };

    return {
      domain: `${localStorage.getItem(`store-domain`)}/`,
      heightMetaDes: 150,
      isLoading: false,
      page: {},
      showHeader: false,
      searchString: 'null hihi',
      attributes: Object.assign({}, defaultForm),
      defaultForm,
      listVariants: [],
      // Product options
      options: [], // need
      errors: [],
      productList: [],
      productFilter: {
        page: 1,
        types: [
          {
            name: 'Product title A-Z',
            value: 'alphaAsc',
          },
          {
            name: 'Product title Z-A',
            value: 'alphaDesc',
          },
          {
            name: 'Highest price',
            value: 'priceAsc',
          },
          {
            name: 'Lowest price',
            value: 'priceDesc',
          },
          {
            name: 'Newest',
            value: 'createdAsc',
          },
          {
            name: 'Oldest',
            value: 'createdDesc',
          },
          {
            name: 'Top sales',
            value: 'totalSalesDesc',
          },
        ],
        type: {
          name: 'Product title A-Z',
          value: 'alphaAsc',
        },
        search: null,
      },
      productSelecteds: [],
      showModalSelectProduct: false,
      imageHelpers,
      imageFake,
      isLoadingProductList: false,
    };
  },
  watch: {
    'attributes.collectionType'() {
      this.attributes.collectionType === 'smart'
        ? (this.attributes.rules = [{ column: 'tags', relation: '', condition: null }])
        : (this.attributes.rules = []);
    },
  },
  methods: {
    getImageUpLoad(images) {
      this.attributes.image = images;
    },
    onCancel() {
      this.$v.$reset();
      this.attributes = Object.assign({}, this.defaultForm);
      this.attributes.rules = [{ column: 'tags', relation: 'contain', condition: null }];
      this.$forceUpdate();
      //website.collections
    },
    async onSubmit() {
      this.$v.$touch();
      utilities.scrollToTop();
      try {
        const isErrosCondition =
          this.attributes && this.attributes.rules ? this.attributes.rules.find(item => item.relation === '') : false;
        console.log(this.attributes.rules, isErrosCondition);
        if (!this.$v.$invalid && !isErrosCondition) {
          this.errors = [];
          this.isLoading = true;
          if (this.attributes && !this.attributes.published) {
            this.attributes.published = false;
          }
          let convertData = {
            ...this.attributes,
            rules: this.attributes.collectionType === 'normal' ? null : this.attributes.rules,
          };
          let res = await collectionApi.create(convertData);

          if (res.data) {
            setTimeout(() => {
              if (res) {
                this.$router.push({ name: 'website.collections.update', params: { id: res.data.collection._id } });
              }
              this.isLoading = false;
            }, 10);
          }
        } else {
          this.errors = ['Validate rule error'];
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    async getProductList() {
      try {
        this.isLoadingProductList = true;
        const params = {
          limit: 10,
          page: this.productFilter.page,
          sort: this.productFilter.type.value,
          search: this.productFilter.search,
        };
        const { data } = await productApi.get(params);
        this.productList = data?.products || [];
      } catch (error) {
        console.log('🚀 ~ file: Create.vue:213 ~ getProductList ~ error:', error);
      } finally {
        this.isLoadingProductList = false;
      }
    },
    onFilterProduct() {
      this.productFilter.page = 1;
    },
    onSaveProductSelected() {
      if (this.attributes.productIds.length) {
        var vm = this;
        this.productSelecteds = this.productList.filter(product => vm.attributes.productIds.includes(product._id));
        this.productFilter.page = 1;
        this.productFilter.search = null;
      }
      this.showModalSelectProduct = false;
    },
    onDeleteProduct(id) {
      this.productSelecteds = this.productSelecteds.filter(product => product._id !== id);
      this.attributes.productIds = this.attributes.productIds.filter(_id => _id !== id);
    },
    onSearchProduct() {
      this.getProductList();
    },
    onClosePopup() {
      this.productFilter.search = null;
      this.showModalSelectProduct = false;
    },
  },
  // watch: {
  //   attributes: {
  //     handler: function(val, oldVal) {
  //       this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
  //     },
  //     deep: true,
  //   },
  // },

  validations: {
    attributes: {
      title: {
        required,
      },
      metaDescription: {
        maxLength: maxLength(160),
      },
      rules: {
        $each: {
          condition: { required },
          column: { required },
          relation: { required },
        },
      },
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
<style lang="scss" scoped>
.product-link {
  color: unset !important;
  line-height: unset !important;
  text-decoration: none;
  max-width: 95%;
  &:hover {
    text-decoration: underline;
  }
}
</style>
